const USER_BASE: string = 'api/v1';
const ADMIN_BASE: string = 'api/v1/admin';

export const API_URL = {
  LOGIN : `/login`,
  LOGOUT: `/logout`,
  PROFILE: `/profile`,
  UPDATE_PROFILE: `/updateProfile` ,
  CHANGE_PASSWORD: `/changePassword`,
  FORGOT_PASSWORD : `/forgotPassword`,
  RESET_PASSWORD : `/resetPassword`,
  GET_CMS : (type) =>`/cms?contentType=${type}`,
  UPDATE_CMS : `/updateContent`,
  DELETE_FAQ : (id) =>`/deleteFaq/${id}`,
  CITY_STATE_SEARCH: `/citySearch`,
  // Users Api
  USERS : `/userList`,
  BLOCK_UNBLOCK_USER : `/userBlock`,
  DELETE_USER : `/userDelete`,
  USER_DETAILS : `/userDetails`,
  UPDATE_CHALLENGE:`/updateMonitor`,
  CONTROL:`/control`,
  UPDATE_CONTROL:`/updateControl`,
  CREATE_CHALLENGE:`/createChallenge`,
  CHALLENGE_LIST:`/challengeList?`,
  DELETE_CHALLENGE:(id)=>`/deleteChallenge/${id} `,
  CHALLENGE_DETAILS:(id)=> `/challengeDetails?challengeId=${id} `,
  EDIT_CHALLENGE:`/editChallenge`,
  PARTICPATE_LIST: `/participateList`,
  UPDATE_INDIVIDUAL_SPEED: `/editSpeed`,
  ANALYTICS:`/analytics`,
  VERSION_LIST:`/versionList`,
  DELETE_VERSION:(id)=>`/deleteVersion/${id}`,
  EDIT_VERSION:`/editVersion`,
  ADD_VERSION:`/addVersion`,
  MONITOR_LIST:`/monitorList`,
  DELETE_MONITOR:(id)=>`/deleteMonitor/${id}`,
  CRON_API:`/resultCal`


}

/**
 * @ACCOUNT_RELATED_END_POINTS
 */
export const LOGIN_API = `${ADMIN_BASE}/login`;
export const LOGOUT_API = `${ADMIN_BASE}/logout`;
export const FORGOT_PASSWORD_API = `${ADMIN_BASE}/forgot-password`;
export const PROFILE = `${ADMIN_BASE}/profile`;
export const RESET_PASSWORD_API = `${ADMIN_BASE}/reset-password`;
export const CHANGE_PASSWORD_API = `${ADMIN_BASE}/change-password`;

/**
 * @DASHBOARD_RELATED_END_POINTS
 */
export const DASHBOARD:string = `${ADMIN_BASE}/dashboard`;

/**
 * @CMS_RELATED_END_POINTS
 */
export const STATIC_CONTENT: string = `${ADMIN_BASE}/content`;
export const STATIC_CONTENT_FAQS: string = `${ADMIN_BASE}/faq`;

/**
 * @USER_RELATED_END_POINTS
 */
export const USERS:string = `${ADMIN_BASE}/users`;

/**
 * @MEDIA_RELATED_END_POINTS
 */
export const MEDIA_UPLOAD:string = `${USER_BASE}/media-upload`;

/**
 * @HOTELS_RELATED_END_POINTS
 */
export const HOTELS:string = `${USER_BASE}/hotel/`;
export const ADD_HOTEL:string = `${USER_BASE}/hotel/save`;
export const HOTEL_DETAILS = `${USER_BASE}/hotel/{hotelId}`;
export const HOTEL_STATUS = `${USER_BASE}/hotel/status`;
export const HOTEL_AMENITIES = `${USER_BASE}/hotel/services`;
export const PRICE_DETAILS = `${USER_BASE}/price-details`;

/**
 * @HOTELS_MENU_RELATED_END_POINTS
 */
export const HOTEL_MENU:string = `${ADMIN_BASE}/menu`;
export const HOTEL_MENU_DETAILS:string = `${ADMIN_BASE}/menu-details`;
export const HOTEL_MENU_STATUS:string = `${ADMIN_BASE}/menu/status`;

/**
 * @EVENT_RELATED_END_POINTS
 */
export const EVENT:string = `${USER_BASE}/event`;
export const ADD_EVENT:string = `${USER_BASE}/event/save`;
export const EVENT_DETAILS:string = `${USER_BASE}/event/details`;
export const EVENT_STATUS:string = `${USER_BASE}/event/status`;

/**
 * @NOTIFICATION_RELATED_END_POINTS
 */
export const NOTIFICATION:string = `${ADMIN_BASE}/notification`;

/**
 * @HELP_AND_SUPPORT_RELATED_END_POINTS
 */
export const HELP_SUPPORT:string = `${USER_BASE}/help-support`;